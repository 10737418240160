export default [
    { id: 1, name: "BMW", price: 530 },
    { id: 2, name: "Google", price: 240 },
    { id: 3, name: "Twitter", price: 650 },
    { id: 4, name: "Apple", price: 720 },
    { id: 5, name: "Microsoft", price: 360 },
    { id: 6, name: "Amazon", price: 410 },
    { id: 7, name: "Facebook", price: 270 },
    { id: 8, name: "Tesla", price: 950 },
    { id: 9, name: "Netflix", price: 180 },
    { id: 10, name: "Intel", price: 810 },
    { id: 11, name: "Samsung", price: 140 },
    { id: 12, name: "Toyota", price: 700 },
    { id: 13, name: "IBM", price: 420 },
    { id: 14, name: "Cisco", price: 510 },
    { id: 15, name: "Adobe", price: 390 },
    { id: 16, name: "Nvidia", price: 320 },
    { id: 17, name: "Sony", price: 560 },
    { id: 18, name: "Oracle", price: 730 },
    { id: 19, name: "SpaceX", price: 600 },
    { id: 20, name: "McDonald's", price: 200 },
    { id: 21, name: "Coca-Cola", price: 110 },
    { id: 22, name: "PepsiCo", price: 510 },
    { id: 23, name: "Nike", price: 670 },
    { id: 24, name: "Disney", price: 420 },
    { id: 25, name: "Visa", price: 850 },
    { id: 26, name: "Mastercard", price: 380 },
    { id: 27, name: "Walmart", price: 920 },
    { id: 29, name: "FedEx", price: 430 },
    { id: 31, name: "Boeing", price: 290 },
    { id: 32, name: "Lockheed Martin", price: 740 },
    { id: 33, name: "General Motors", price: 520 },
    { id: 34, name: "Honda", price: 780 },
    { id: 35, name: "Mitsubishi", price: 150 },
    { id: 36, name: "Volkswagen", price: 810 },
    { id: 37, name: "Mercedes-Benz", price: 360 },
    { id: 38, name: "Audi", price: 200 },
]
